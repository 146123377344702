import React  from 'react';
import Button from '@material-ui/core/Button';
import './styles.scss';

import {
  Route,
  Link,
  Switch
} from 'react-router-dom';

function NavButton ({ label, path, to, variant }) {
  return (
    <div className="menu-button">
    <Switch>
      <Route exact path={path}>
        <Link to={to} className={"button active"}>
          <Button size="small" variant="contained">{label}</Button>
        </Link>
      </Route>
      <Route>
        <Link to={to} className={"button"}>
          <Button size="small" variant={variant} style={{color:"white"}}>{label}</Button>
        </Link>
      </Route>
    </Switch>
    </div>
  );
}

function TopMenu() {
  return (
    <div id="top-menu">
      <div className="button-set">
        <NavButton label="Home" path="/" to="/" />
        {/*
        <NavButton label="Store" path="/store" to="/store" />
        <SplitButton  
          size    = "small"
          title   = "Services"
          options = {[
            'Medical Cleaning',
            'Residential Dry Cleaning',
            'Restoration',
          ]}
        />
        <NavButton label="About Us" path="/about"   to="/about"   />
        */}
        <NavButton label="Contact"  path="/contact" to="/contact" />
        {/*
        <NavButton
          label   = "Log In"
          path    = "/login"
          to      = "/login"
          variant = "contained"
        />
        */}
      </div>
    </div>
  );
}

export default TopMenu;
