import React       from 'react';
import logo        from './assets/header-logo.png';
import star        from './assets/diamond.svg';
import TopMenu     from './components/TopMenu';
//import CovidBanner from './components/CovidUpdate';

import { Link } from 'react-router-dom';
import {
  // Switch,
  // Route,
} from 'react-router-dom';

import './styles.scss';


function Header() {
  var stars = [];
  for (var i = 0; i < 50; i++) {
    var altitude = Math.random() * 150;

    stars.push(
      <img src    = {star}
        className = {"star star"+(i%5)}
        key       = {`star${i}`}
        style     = {{
          position : "absolute",
          width    : (Math.random() * 4 + 4) + "px",
          top      : altitude + "px",
          opacity  : 0.75 - (altitude / 225),
          left     : (Math.random() * 100)+"%",
        }}
        alt="star"
      />
    );
  }

  return (
    <div className="app-header"
      style={{
        width              : "100%",
        backgroundRepeat   : "no-repeat",
        backgroundSize     : "100% 100%",
      }}
    >

      <div className="starscape">{stars}</div>

      <TopMenu/>

      <content-block class="centered fill-80 hero-bar">
          <Link to="/" className="site-logo fill-50">
            <img src={logo}
              alt="OnSite Drapery Cleaning Logo"
              className="header-logo" />
          </Link>

          <div className="slogan fill-40">
            <p>
              Serving North Carolina's hospitals, homes, and businesses.
            </p>
          </div>
      </content-block>


      {/*
			<Switch>
				<Route path='/covid19/*'><CovidBanner size="compact"/></Route>
				<Route path='/store'    ><CovidBanner size="store"/></Route>
				<Route                  ><CovidBanner size="normal" /></Route>
			</Switch>
      */}


    </div>
  );
}

export default Header;
