import React from 'react';
import './styles.scss';

import {
  Link
} from 'react-router-dom';

import medical     from './assets/scenes/small/nurse.svg';
import residential from './assets/scenes/small/housewife.svg';
import commercial  from './assets/scenes/small/office.svg';
import restoration from './assets/scenes/small/restoration.svg';
import popOut      from './assets/scenes/large/woman-chair.svg';

const images = {
  medical, 
  residential,
  commercial,
  restoration,
  popOut,
};

function LinkCard ({to, image, title}) {
  return (
    <Link to={to} className="client-type-nav-link">
      <img src={image} alt="Medical Cleaning" />
      <p>{title}</p>
    </Link>
  );
}

function App() {

  return (
    <div id="page-landing">

      <h1>Fast, Safe Disinfection</h1>
      <content-block class="fill-80">
        <p className="spaced-wide">
          OnSite Drapery Cleaning specializes in medical-grade cleaning of
          hard-to-clean surfaces such as hospital supplies, curtains,
          draperies, upholstered surfaces, cubicle walls, and more.
        </p>

        <p className="spaced-wide">
          Our process conforms to EPA-registered kill claims certified to
          kill coronavirus (including Covid-19) and flu, along with many
          other known pathogens.
        </p>

				<p className="text-primary">
					E-mail us any time to ask about any of our services or request a
					free quote.
				</p>

				<content-block class="centered">
					<a className="service-email fill-40"
						href="mailto:service@onsitedeepclean.com">
						service@onsitedeepclean.com
					</a>

					<a className="service-phone fill-40"
						href="tel:9195042532">(919) 504-2532</a>
				</content-block>

        <p className="spaced-wide">
          Click the links below to find out how we can help you with your
          medical facility, residence, or commercial space during this
          pandemic.
        </p>

      </content-block>

      <content-block class="centered">
        <LinkCard to="/medical"     
          image={images.medical}
          title="Medical"/>

        <LinkCard to="/residential"
          image={images.residential}
          title="Residential"/>

        <LinkCard to="/commercial"
          image={images.commercial}
          title="Commercial"/>
      </content-block>


    </div>
  );
}

export default App;
