import React from 'react';
import './styles.scss';

import iconQuote    from './assets/scenes/small/medical-quote.svg';
import iconSchedule from './assets/scenes/small/medical-schedule.svg';
import iconInvoice  from './assets/scenes/small/online-invoice.svg';
import ActionButton from '../../../components/ActionButton';

function App() {

  return (
    <div className="page" id="page-residential">

      {/* Header */}
      <h1>
        Fabric Cleaning On-Premises
      </h1>

      <p className="text-primary">
        Get professional cleaning of all your fabrics and textiles in your
        place of business!  OnSite Drapery Cleaning provides on-premises wet
        or dry cleaning for any kind of furniture, window-treatment, or
        other fabrics in your place of business&mdash;whether an office,
        showroom, storefront, customer area, or anything else.
      </p>

      <h2>
        Available Services
      </h2>

      <p className="text-primary">
        We clean and disinfect (upon request) the following items:
      </p>

      <content-block class="centered fill-80">
        <span className="cleanable-item">Draperies</span>
        <span className="cleanable-item">Blinds</span>
        <span className="cleanable-item">Curtains</span>
        <span className="cleanable-item">Upholstery</span>
        <span className="cleanable-item">Silks</span>
        <span className="cleanable-item">Tapestries</span>
        <span className="cleanable-item">Rugs</span>
        <span className="cleanable-item">...and more!</span>
      </content-block>

      <h3>
        Dry Cleaning vs. Wet Cleaning
      </h3>

      <p className="text-primary">
        Some draperies, blinds, and furniture must be dry-cleaned.  Others
        must be wet-cleaned (for satisfactory results).  Whether we are
        treating your fine silk draperies and furniture, or treating your
        blackout curtains for dust mites, we will help you pick the right
        solution for your needs.
      </p>

      <p className="text-primary">
      Find out more about <a href="#dry-cleaning">Dry Cleaning</a>
      vs
      <a href="#wet-cleaning">Wet Cleaning</a>
      in your place of business.
      </p>

			<vertical-spacer />

      <content-block class="centered" style={{transform: "scale(1.5)"}}>
        <ActionButton/>
      </content-block>

      <hr/>

      <step-list>

        <step-list-title>
          How it Works
        </step-list-title>

        {/****************************/}
        <step-card>
          <step-number>Step 1</step-number>
          <step-title>Get a Free Quote</step-title>
          <step-copy>
            <p>
              Contact us for a free quote.  We will come out to your
              place of business to help determine which services are appropriate
              for you.  The whole process only takes about 20 minutes, and
              you will get your quote promptly via e-mail.
            </p>

            <ActionButton/>
          </step-copy>

          <step-image>
            <img src={iconQuote} alt="Medical Quote"/>
          </step-image>
        </step-card>

        {/****************************/}
        <step-card card-align="right">
          <step-number>Step 2</step-number>
          <step-title>Schedule an Appointment</step-title>

          <step-image>
            <img src={iconSchedule} alt="Medical Schedule"/>
          </step-image>

          <step-copy>
            <p>
              Scheduling an appointment is quick and easy.  When you're
              ready, just let us know your availability and we will find a
              time that works best for you.
            </p>

            <p>
              One of our teams will arrive promptly at the scheduled time.
              Cleanings typically take one to two hours&mdash;they are
              quick, quiet, and discrete.
            </p>
          </step-copy>
        </step-card>

        {/****************************/}
        <step-card>
          <step-number>Step 3</step-number>
          <step-title>Pay online</step-title>

          <step-copy>
            <p>
              Within 24 hours of your cleaning, you will receive a
              convenient invoice to your email.  You can pay online in
              moments, or mail us a check.
            </p>

            <p>
              The e-mailed invoice will have a printable, PDF copy, and you
              will receive a receipt for your records immediately.
            </p>
          </step-copy>

          <step-image>
            <img src={iconInvoice} alt="Online Invoice"/>
          </step-image>
        </step-card>

      </step-list>


      <a name="dry-cleaning" href="#dry-cleaning">
      <h3>Dry Cleaning in Your Business</h3>
      </a>

      <p>
        Dry cleaning in your place of business is a safe and effective way
        of caring for your draperies, furniture, and other fine fabrics.
      </p>

      <p>
        Fully synthetic fibers (as are usually found in office furniture, for
        example) generally do not need to be dry cleaned.  It is our policy,
        however, to dry clean any items when the type of fiber cannot be
        known for certain.
      </p>

      <p>
        We use only safe, industry-standard dry cleaning solvents recognized
        by many places (such as New York City) as non-toxic and reliable.
      </p>

      <a name="wet-cleaning" href="#wet-cleaning">
      <h3>Wet Cleaning in Your Business</h3>
      </a>

      <p>
        Many items are better cleaned with water-based processes.  Examples
        include durable upholstery, carpets, blackout curtains, sheers, and
        other similar items.  
      </p>

      <p>
        Our wet-cleaning process can be combined with medical-grade
        disinfectant that kills microbes, allergens, molds, and more.  Ask
        about our sanitization services at when you request your free,
        on-site quote.
      </p>

			<vertical-spacer/>

      <content-block class="centered" style={{transform: "scale(1.5)"}}>
        <ActionButton/>
      </content-block>

    </div>
  );
}

export default App;
