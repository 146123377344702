import React from 'react';
import bkg   from './assets/footer.png';
import './styles.scss';

import {
  Link
} from 'react-router-dom';

function Footer() {

  return (
    <div className="Footer">
      <div className="app-footer"
        style={{
          backgroundImage    : `url(${bkg})`,
          width              : "100%",
          backgroundRepeat   : "no-repeat",
          backgroundSize     : "100% 100%",
          backgroundPosition : "left",
          position           : "relative",
          minHeight          : "140px",
          marginTop          : "7em",
        }}
      >

      <div className="content">
        
        <div className="column summary">
          <p className="spaced-normal">
            OnSite Drapery Cleaning provides industry-best cleaning and
            disinfection services for hospitals, clinics, residences, and
            commercial spaces.
          </p>

          <p className="spaced-normal">
            Contact us for a free quote.
          </p>
        </div>

        <div className="column links fill-20">
          <Link to="/contact">Contact Us</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>


      </div>

      <div className="bottom fill-80">
        <div className="left">
          <div className="website">OnSite Drapery Cleaning, LLC.</div>
          <div className="copyright">Copyright &copy; 2020</div>
        </div>

        <div className="right">
        </div>
      </div>

      </div>
    </div>
  );
}

export default Footer;
