import React from 'react';
import './styles.scss';

function Page() {

  return (
    <div id="page-login">

      <form class="login-form centered fill-50">
        <h1>Log In</h1>

        <div className="login-field email">
          <label for="email">E-mail</label>
          <input type="text" name="email" placeholder="E-mail Address"/>
        </div>
      
        <div className="login-field password">
          <label for="password">Password</label>
          <input type="password" name="password" placeholder="Password"/>
        </div>

        <div className="login-field submit">
          <input type="submit" name="submit" value="Log in" className="primary dark"/>
        </div>

      </form>

    </div>
  );
}

export default Page;
