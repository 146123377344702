import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import './styles.scss';

function ActionButton({
  content,
  align = "left",
}) {

  return (
    <Link to="/contact">
			<Button
				variant = "contained"
				size    = "large"
				color   = "primary"
			>   
				Get a Free Quote
			</Button>
    </Link>
  );

}

export default ActionButton;
