import React from 'react';
import './styles.scss';

import medicalQuote       from './assets/scenes/small/medical-quote.svg';
import medicalSchedule    from './assets/scenes/small/medical-schedule.svg';
import medicalCertificate from './assets/scenes/small/medical-certificate.svg';

import brandNCState from './assets/brands/ncstate-brick-2x1-red.png';
import ActionButton from '../../../components/ActionButton';

function Page () {

  return (
    <div className="page" id="page-medical">

      <h1>
        On-Site Medical Cleaning
      </h1>

      <p className="text-primary">
        For hospitals and clinics looking for fast, convenient cleaning of
        facility fabrics, OnSite Drapery Cleaning provides medical-grade 
        cleaning of all your hard-to-clean fabrics.  Our documented,
        audit-ready processes adhere to best practices and EPA kill-claim
        methodologies to kill a wide variety of pathogens including <em>c
        Diff.</em>, <em>vegetative bacteria</em>, and much more.
      </p>

      <p className="text-primary">
        We clean curtains, patient and staff seating, window treatments
        (including blinds and pull-shades), PT/OT equipment, pediatric
        therapy equipment, wheelchairs, and much more.
      </p>

      <p className="text-primary">
        Unlike other cleaners, we clean your curtains, patient seating, and
        window treatments on-site.  This means we <em>never</em> lose a
        single curtain.
      </p>

      <h2>
        Clients
      </h2>

      <p className="text-primary">
        We service a wide variety of hospitals and clinics, including dozens
        of facilities in these healthcare systems:
      </p>

      <div className="client-networks">
        <p className="text-primary">
          <div className='duke-word brand-identity'>Duke</div>
          <div className="brand-identity">
            <img src={brandNCState} alt="NC State" style={{height: "100%"}}/>
          </div>
          <div className='brand-identity independent'>
            Independent Practices
          </div>
        </p>
      </div>

      <p className="text-primary">
        If you are a part of one of these systems, or are an independent
        facility, please contact us for information about how to take
        advantage of our services within your health system.
      </p>

      <p className="text-primary">
        Contact us for a free, on-site quote to see how we can help keep
        your facility clean and patient-ready.
      </p>

      <vertical-spacer/>

      <content-block class="centered" style={{transform: "scale(1.5)"}}>
        <ActionButton/>
      </content-block>

      <vertical-spacer/>

      <hr/>

      <step-list>

        <step-list-title>
          How it Works
        </step-list-title>

        {/****************************/}
        <step-card>
          <step-number>Step 1</step-number>
          <step-title>Get a Free Quote</step-title>
          <step-copy>
            <p>
              Contact us for a free quote.  We will come out to your
              facility to help determine which services are appropriate
              for you.  The whole process only takes about 20 minutes, and
              you will get your quote promptly via e-mail.
            </p>

            <ActionButton/>
          </step-copy>

          <step-image>
            <img src={medicalQuote} alt="Medical Quote"/>
          </step-image>
        </step-card>

        {/****************************/}
        <step-card card-align="right">
          <step-number>Step 2</step-number>
          <step-title>Schedule an Appointment</step-title>

          <step-image>
            <img src={medicalSchedule} alt="Medical Schedule"/>
          </step-image>

          <step-copy>
            <p>
              Scheduling an appointment is quick and easy.  When you're
              ready, just let us know your availability and we will find a
              time that works best for you.
            </p>

            <p>
              One of our teams will arrive promptly at the scheduled time.
              Cleanings typically take one to two hours&mdash;they are
              quick, quiet, and discrete.
            </p>
          </step-copy>
        </step-card>

        {/****************************/}
        <step-card>
          <step-number>Step 3</step-number>
          <step-title>Receive Your Audit-Ready Certificates</step-title>

          <step-copy>
            <p>
              After your cleaning, we send you digital and printable copies
              of your cleaning certification and process document.  These
              documents are essential for audit-readiness of your facility.
            </p>

            <p>
              In addition, we keep records of your cleanings and can provide
              you with historical information any time, at no charge.
            </p>
          </step-copy>

          <step-image>
            <img src={medicalCertificate} alt="Medical Certificate"/>
          </step-image>
        </step-card>

      </step-list>

      <hr/>

      <h2>
        Best Practices in HAI Control
      </h2>

      <p className="text-primary">
        Health regulatory departments recognize the role that cleaning
        curtains, upholstered furniture, and other fabrics in controlling
        Healthcare-Associated Infections (HAI).
      </p>

      <p className="text-primary">
        We recommend our clients follow the typical recommendation of deep
        cleaning curtains, patient seating, and window treatments on a
        semi-annual basis (regular, 6-month appointments).
      </p>

      <p className="text-primary">
        In addition to semi-annual cleanings, facilities may also opt to
        have quick-service sanitization.  Our inexpensive Quick-Service
        Sanitization usually takes about 15-20 minutes and sanitizes all the
        fabric surfaces in your facility.
      </p>

      <vertical-spacer/>

      <content-block class="centered" style={{transform: "scale(1.5)"}}>
        <ActionButton/>
      </content-block>

    </div>
  );
}

export default Page;
