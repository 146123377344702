import React from 'react';
import './styles.scss';

import {
  Link
} from 'react-router-dom';

import medical     from './assets/scenes/small/nurse.svg';
import residential from './assets/scenes/small/housewife.svg';
import commercial  from './assets/scenes/small/office.svg';
import restoration from './assets/scenes/small/restoration.svg';
import popOut      from './assets/scenes/large/woman-chair.svg';

const images = {
  medical, 
  residential,
  commercial,
  restoration,
  popOut,
};

function LinkCard ({to, image, title}) {

  return (
    <Link to={to} className="client-type-nav-link">
      <img src={image} alt="Medical Facilities" />
      <p>{title}</p>
    </Link>
  );
}

function App() {

  return (
    <div id="page-landing">

      <content-block class="centered">
        <LinkCard to="/medical"     
          image={images.medical}
          title="Medical"/>

        <LinkCard to="/residential"
          image={images.residential}
          title="Residential"/>

        <LinkCard to="/commercial"
          image={images.commercial}
          title="Commercial"/>
      </content-block>

      <vertical-spacer/>
      <vertical-spacer/>

      <content-block class="centered callout fill-80">
        <img src={popOut} alt="Why choose us?" className='fill-40'/>

        <horizontal-spacer/>

        <div className='fill-50'>
          <h2>Why Choose Us?</h2>

          <p>
            OnSite Drapery Cleaning has been serving the North Carolina area
            for over 20 years.  We are trusted by hospitals, clinics,
            residences, and businesses to provide top-quality cleaning
            services for draperies, curtains, furniture, and window
            treatments.
          </p>
        </div>
      </content-block>

    </div>
  );
}

export default App;
