import React  from 'react';

import {
  BrowserRouter,
  Route,
} from 'react-router-dom';

// Components
import Footer         from './components/Footer';
import Header         from './components/Header';
import PageContent    from './components/PageContent';
import ScrollToTop    from './components/ScrollToTop';

// Pages
import Commercial     from './pages/client-types/Commercial';
import Contact        from './pages/Contact';
import Covid19Learn   from './pages/covid-19/Learn';
import Covid19Service from './pages/covid-19/OnSite';
import Landing        from './pages/Landing';
import Login          from './pages/Login';
import Medical        from './pages/client-types/Medical';
import PrivacyPolicy  from './pages/PrivacyPolicy';
import Residential    from './pages/client-types/Residential';
import Restoration    from './pages/client-types/Restoration';
import SchoolGovt     from './pages/client-types/Schools';

// Page-specific styles
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Header/>

        <PageContent>
          <Route exact path="/"                 component={Landing} />

          {/* Customer types */}
          <Route exact path="/commercial"       component={Commercial } />
          <Route exact path="/medical"          component={Medical    } />
          <Route exact path="/residential"      component={Residential} />
          <Route exact path="/restoration"      component={Restoration} />
          <Route exact path="/schools-and-govt" component={SchoolGovt } />

          {/* Info pages */}
          <Route exact path="/about"         component={Landing}       />
          <Route exact path="/contact"       component={Contact}       />
          <Route exact path="/login"         component={Login}         />
          <Route exact path="/services"      component={Landing}       />
          <Route exact path="/privacy-policy"component={PrivacyPolicy} />

          {/* Covid-19 pages */}
          <Route exact path="/covid19/get-service" component={Covid19Service} />
          <Route exact path="/covid19/learn"  component={Covid19Learn} />
        </PageContent>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
