import React from 'react';
import './styles.scss';

import peroxide      from './assets/peroxide.svg';
import colorfast     from './assets/color-fast.svg';
import antibacterial from './assets/antibacterial.png';
import professional  from './assets/home-1.svg';
import dirty         from './assets/keep-it-dirty.png';
import pdf           from './assets/disinfecting-with-h2o2-onsite-drapery-cleaning.pdf';

function App() {

  return (
    <div id="page-covid-learn">

    <content-block class="fill-80">
      <h1>How to Disinfect at Home</h1>

      <p>
        <strong>
          The CDC has issued guidance on how to clean your home in light of
          Covid-19.  Chairs, couches, draperies, car seats, upholstered
          chairs, etc. are difficult to clean and disinfect. These
          instructions can be used to clean soft and hard surfaces in your
          home or place of business. 
        </strong>
      </p>

      <p>
        <strong>Soft-surface Cleaning</strong> &mdash;
        The method described on this page can be used to disinfect soft
        surfaces such as chairs, couches, draperies, car seats, upholstery,
        and so on.  Remember to let the fabric air dry for maximum
        disinfection.
      </p>

      <p>
        <strong>Hard-surface Cleaning</strong> &mdash;
        This method can also be used to disinfect hard surfaces.  For hard
        surfaces, simply use the same peroxide preparation described below
        and allow the peroxide 30 seconds to work before wiping away with a
        clean, dry cloth.
      </p>

      <p>
        You can download
        <a href={pdf} target="_blank" rel="noopener noreferrer">a pdf version</a>
        of this document, as well.
      </p>
    </content-block>


    <content-block class="fill-80">
    <hr/>

    <h2>Table of Contents</h2>

    <ol>
      <li>
        <a href="#household-items">Some Household Items that Kill Covid-19</a>
      </li>
      <li>
        <a href="#peroxide-kills">Hydrogen Peroxide kills Covid-19</a>
      </li>
      <li>
        <a href="#how-to-dilute">How to Dilute Hydrogen Peroxide</a>
      </li>
      <li>
        <a href="#how-to-test">How to Test Fabrics for Color-Safeness</a>
      </li>
      <li>
        <a href="#how-to-treat">How to Disinfect Your Fabrics</a>
      </li>
      <li>
        <a href="#how-to-get-help">How to Get Professional Cleaning</a>
      </li>
    </ol>

		<hr/>

    <h2 id="household-items">Some household items that kill Covid-19</h2>

    <ul>
      <li>Hydrogen Peroxide</li>
      <li>Rubbing Alcohol (isopropyl alcohol)</li>
			<li>Drinking Alcohol (ethyl alcohol)</li>
      <li>Bleach (Sodium hypochlorite)</li>
    </ul>

    <p>
      If you have these in your home, you most likely didn't buy them as a
      disinfectant, but for some other purpose.  Hydrogen peroxide is an
      antiseptic you can use for cuts and scrapes.  Rubbing alcohol can
      remove sticky substances and clean glass.  Drinking alcohol can be
      used recreationally, of course, and bleach is used to whiten clothes.
    </p>

    <p>
      But all of these, if used correctly, can also disinfect surfaces!  The
      CDC has published a list of products known to kill Covid-19, along
      with two very important other pieces of information:
    </p>

    <ol>
      <li>the active ingredient (this is the chemical that does the disinfecting), and</li>
      <li>the <em>contact time</em> (this is how long the chemical has to touch the surface to disinfect it)</li>
    </ol>

    <p>
      You can find that information on
      <a href="https://www.ecri.org/components/HDJournal/Pages/Disinfectant-Concentrations-for-EPA-list-N-COVID-19.aspx?tab=2">
      products (chemicals) that kill Covid-19
      </a> summarized by the folks at <a href="https://www.ecri.org">ECRI</a>.
    </p>

    <p>
      At OnSite Drapery Cleaning, we use hydrogen peroxide-based
      disinfectants.  The remainder of this page will discuss hydrogen
      peroxide as a cleaner and disinfectant.  For more information about
      using other items on the list, search the web.  Here are some
      example links.
    </p>

    <ul>
      <li>
        <a href="https://duckduckgo.com/?q=how+to+disinfect+with+alcohol&t=canonical">How to disinfect with alcohol</a>
      </li>
      <li>
        <a href="https://duckduckgo.com/?q=how+to+disinfect+with+bleach&t=canonical">How to disinfect with bleach</a>
      </li>
    </ul>

    <hr/>

    <h2>Keeping your Fabrics Safe, Too</h2>

    <p>
     While most fabrics can be treated with hydrogen peroxide, some
     cannot.  Use these instructions to test that your fabrics are color
     safe.
    </p>

    <h4>Color-Safe Fabrics</h4>

    <p>
      Before cleaning with hydrogen peroxide, be sure to test that your
      fabrics are color safe.  Hydrogen peroxide can bleach fabrics in some
      cases.  Use the instructions provided to test your fabrics before
      treatment.
    </p>

    <h4>Silks and other Delicate Fabrics</h4>

    <p>
      Do not use hydrogen peroxide (or any water solution) on silk!  Some silk
      can be safely treated, but often it can leave permanent spots and
      stains.  This is better handled by a professional.
    </p>

    <hr/>

    <h2 id="how-to-dilute">How to Dilute Hydrogen Peroxide</h2>

    <content-block class="centered image-wrapper">
      <img alt="Peroxide" src={peroxide} className="fill-70"/>
    </content-block>

    <p>
      <strong>IMPORTANT:</strong> The products that kill Covid-19 (see the
      link above) that use hydrogen peroxide typically have a concentration
      of about 0.5% to about 1.4%.  This is all you need to kill Covid-19 if
      you let the product sit on the surface for about a minute.
    </p>

    <p>
      Hydrogen peroxide is typically sold in concentrations of 3% in brown
      bottles.  
    </p>
        
    <p className="recipe">
      To reduce the concentration to 0.5% you need to add the water in 
      a 5:1 ratio – (five times as much water as 3% hydrogen peroxide).
    </p>

    <p className="recipe">
      To reduce the concentration to 1.4% you need to add the water in 
      about a 1:1 ratio (for every 12 oz of peroxide, add 14 oz water).
    </p>

    <p>
      For the remainder of this article, we will assume you want a 0.5%
      hydrogen peroxide mixture.
    </p>

    <h4>How much water should I add?</h4>

    <ol>
      <li>
        Measure 1 cup of Hydrogen Peroxide into a large bowl or gallon jug.
      </li>
      <li>
      	Add 5 cups of purified water.
      </li>
      <li>
        Mix thoroughly for a few seconds.  The hydrogen peroxide is now at a
        concentration of 0.5%.
      </li>
    </ol>

    <hr/>

    <h2 id="how-to-test">How to Test Fabrics for Color-Safeness</h2>

    <content-block class="centered image-wrapper">
      <img alt="Colorfast" src={colorfast} className="fill-70"/>
    </content-block>

    <p>
      You should test fabrics to ensure they are color safe.  Follow these
      steps:
    </p>

    <ol>
      <li>
        Get a light-colored washcloth or rag.
      </li>
      <li>
        Dip a small spot into the 0.5% hydrogen peroxide.
      </li>
      <li>
        Find a clean, inconspicuous place on your fabric item to test.
      </li>
      <li>
        Rub the wet part of the washcloth on the fabric in circular motions
        for about 30 seconds.  Stop every 5-10 seconds to check the cloth.
      </li>
    </ol>

    <p>
      If there is no color on the washcloth, then it is color safe for the
      solution.
    </p>

    <p>
      If there is color on the washcloth,
    </p>

    <ol>
      <li>
        Check to see if the color is dirt.  You may have chosen a soiled
        spot on the material and transferred some of the soil to the
        washcloth.
      </li>
      <li>
        If the color is not dirt (for example, red from a red chair), then
        the fabric is not color safe for this solution.
      </li>
    </ol>

    <hr/>

    <h2 id="how-to-treat">How to Disinfect Your Fabrics</h2>

    <content-block class="centered image-wrapper">
      <img alt="Antiviral" src={antibacterial} style={{width: "140px", height: "140px"}} />
    </content-block>

    <ol>
      <li>
      	Get a 0.5% concentration of hydrogen peroxide (a little stronger
        won’t hurt, but too strong and it can act as a bleach!).
      </li>
      <li>
      	Test your fabrics for color-safeness.
      </li>
      <li>
        Lightly dampen your fabrics with the solution.  Use a spray bottle
        for even coverage.  Remember, you do not need to drench the fabric,
        just get it damp.
      </li>
      <li>
        IMPORTANT:  Let the fabric air-dry over the next 30 minutes or so.
        During this process, the hydrogen peroxide will be disinfecting
        your fabric.  Do not try to speed the process up!
      </li>
    </ol>


    <p>
      Remember:  Disinfecting is not the same as cleaning!  This will not
      remove dirt, it will only kill pathogens like the Covid-19 and flu.
      If you would like to clean your fabrics, do this before treating
      them with hydrogen peroxide so you do not remove it before it has
      disinfected your fabrics!
    </p>

    <p>
      For a list of other EPA-registered disinfectants, visit our website
      at:  www.onsitedeepclean.com
    </p>

    <hr/>

    <h2 id="how-to-get-help">How to Get Professional Cleaning</h2>

    <content-block class="centered image-wrapper">
      <img alt="Professional Clean" src={professional} className="fill-70"/>
    </content-block>


    <p>
      OnSite Drapery Cleaning can help with difficult to clean items—like
      draperies, sofas, chairs.  If you would like to have your home
      professionally cleaned and disinfected, get in touch with us for free
      quote!
    </p>

    <content-block class="centered">

      <a className="service-email fill-40"
         href="mailto:service@onsitedeepclean.com">
        service@onsitedeepclean.com
      </a>

      <a className="service-phone fill-40" href="tel:9195042532">
        (919) 504-2532
      </a>

    </content-block>

    <p>
      We also have a limited numbers of hydrogen peroxide spray bottles and
      refill gallons available for porch drop-off in Durham (for no delivery
      cost).
    </p>

    <div className="fill-50">
      <p>
        OnSite Drapery Cleaning is a locally-owned company located in Durham.
        We have serviced Duke hospital, homes, and businesses throughout
        North Carolina for the past 20 years!
      </p>

      <p>
        We clean and disinfect fabrics and hard-to-clean surfaces, keeping
        North Carolina safe, clean, and healthy.
      </p>
    </div>

    <div className="fill-10"></div>

    <div className="fill-40">
      <img alt="Keep it Dirty, Durham (but not *that* dirty!)" src={dirty} style={{width: "130px", height: "150px"}}/>
    </div>


    <hr/>

    <h2>More Resources about Cleaning for Covid-19</h2>

      <p className="spaced-wide">
        The US CDC and NC Department of Health and Human Services have
        released information and recommendations for cleaning in
        businesses, homes, and medical facilities.  Find the info you're
        looking for at the links below.  (All links open in a new window).
      </p>

      <h3>Center for Disease Control and Prevention (CDC)</h3>
      <ul>
        <li>
          <a href="https://www.cdc.gov/coronavirus/2019-ncov/prepare/cleaning-disinfection.html">
            CDC: Cleaning and Disinfecting in Your Home
          </a>
        </li>
      </ul>
      <h3>NC Dept. of Health and Human Services</h3>
      <ul>
        <li><a href="https://www.ncdhhs.gov/divisions/public-health/coronavirus-disease-2019-covid-19-response-north-carolina/businesses-employers" target="_blank" rel="noopener noreferrer">Recommendations for Businesses and Employers</a></li>
        <li><a href="https://epi.dph.ncdhhs.gov/cd/coronavirus/_Interim%20Guidance%20for%20Environmental%20Cleaning%20for%20Non-Healthcare%20Settings.pdf?ver=1.1" target="_blank" rel="noopener noreferrer">Guidance for Cleaning when Someone is Infected with Covid-19</a></li>
        <li><a href="https://www.ncdhhs.gov/divisions/public-health/coronavirus-disease-2019-covid-19-response-north-carolina/preparing-and" target="_blank" rel="noopener noreferrer">Preparing and Responding to Covid-19</a></li>
      </ul>
      </content-block>



    </div>
  );
}

export default App;
