import React from 'react';
import './styles.scss';

function Page() {

  return (
    <div id="page-contact">

      <h1>Contact Us for a Free Quote</h1>

      <p className="text-primary">
        E-mail us any time to ask about any of our services or request a
        free quote.
      </p>

      <vertical-spacer/>

      <content-block class="centered">

        <a className="service-email fill-40"
          href="mailto:service@onsitedeepclean.com">
          service@onsitedeepclean.com
        </a>

        <a className="service-phone fill-40"
          href="tel:9195042532">(919) 504-2532</a>
      </content-block>

    </div>
  );
}

export default Page;
