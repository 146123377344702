import React from 'react';
import './styles.scss';

function Page() {
  return (
    <div className="page" id="page-landing">

      <div className="section">
        <div className="left pop-out panel">
        </div>

        <div className="right panel promo">
          <h2>Why Choose Us?</h2>
          <p>
            OnSite Drapery Cleaning has been serving the North Carolina area
            for over 20 years.  We are trusted by hospitals, clinics,
            residences, and businesses to provide top-quality cleaning
            services for draperies, curtains, furniture, and window
            treatments.
          </p>
        </div>
      </div>

    </div>
  );
}

export default Page;
